// file deepcode ignore HardcodedNonCryptoSecret: file contains only public api keys

export const environment = {
  env: 'staging',
  production: true,
  api: 'https://staging.patientpal.io',
  wss: 'wss://8nfuzjyasa.execute-api.us-east-1.amazonaws.com/staging/',
  fbAppId: '2023765024380144',
  googleAnalyticsId: '',
  googleMapsApiKey: 'AIzaSyDQwtD1hyMDqugLPIDCCk7GeOR5RNZRBhs',
  recaptcha3SiteKey: '6Lcnzp4UAAAAABbBfqSy6VP_8kmPIFMHRHjwt3VL',
  sentryDSN: 'https://38207e203dfb4364ad89b2c5ff55dc3d@sentry.io/1514630',
  stripePublicKey: 'pk_test_bW3vvSPQXKkAwWN5fprnkOTS',
  tilledUrl: 'https://sandbox-app.tilled.com',
  tilledPublicKey:
    'pk_hzQJugeKjvqhBL1nTqf7BqBfLiVQDmGZBXuLbymhwj5TGZbGOcUGJvCmAEAcHnfJbfeE4Nd6thbJb9pUPyFBjZVTr3OJtWxnL8yt',
  tilledProductionMode: false,
  idleTimer: 5,
}
