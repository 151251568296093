// file deepcode ignore HardcodedNonCryptoSecret: file contains only public secrets

export const environment = {
  env: 'staging',
  production: true,
  api: 'https://staging.patientpal.io',
  wss: 'wss://8nfuzjyasa.execute-api.us-east-1.amazonaws.com/staging/',
  googleAnalyticsId: '',
  googleMapsApiKey: 'AIzaSyDQwtD1hyMDqugLPIDCCk7GeOR5RNZRBhs',
  recaptcha3SiteKey: '6Lcnzp4UAAAAABbBfqSy6VP_8kmPIFMHRHjwt3VL',
  sentryDSN: 'https://320fbf707b834a1d9f71afad84f56254@sentry.io/1514871',
  vapidPublicKey:
    'BPM0iUc2R0knidDFngUmT5PSCZuIOVZ2fR0ATAAY2nvg3NDp38H0AAR_aR1b15WYSlvKzGk6ZV-CPgiBgGOajWs',
  stripePublicKey: 'pk_test_bW3vvSPQXKkAwWN5fprnkOTS',
  tilledUrl: 'https://sandbox-app.tilled.com',
  tilledPublicKey:
    'pk_hzQJugeKjvqhBL1nTqf7BqBfLiVQDmGZBXuLbymhwj5TGZbGOcUGJvCmAEAcHnfJbfeE4Nd6thbJb9pUPyFBjZVTr3OJtWxnL8yt',
  tilledProductionMode: false,
}
